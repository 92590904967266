import dayjs from 'dayjs';
import * as React from 'react';
import { Helmet } from "react-helmet";
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { addDoc, updateDoc, doc, collection, Timestamp } from 'firebase/firestore';
import { db } from '../firebase';
import { useState, useCallback } from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';









const cars = collection(db, 'cars');


const steps = ['Personal', 'Location', 'Limo&Car'];



const theme = createTheme();

const Booking = () => {


    const currentT = new Date();
    const datenowT = `${currentT.getFullYear()}-${currentT.getMonth()}-${currentT.getDate()}T${currentT.getHours() + 1}:00`;


    const [controllerPickupTime, setControllerPickupTime] = React.useState(dayjs(datenowT));
    const hCsetControllerPickupTime = (event) => {
        setControllerPickupTime(event);
        console.log(controllerPickupTime.toString());
        console.log("DAY: " + controllerPickupTime);
    };


    //Personal Information Variable

    const [controllerName, setControllerName] = React.useState('');
    const hCsetControllerName = (event) => {
        setControllerName(event.target.value);
    };

    const [controllerSurname, setControllerSurname] = React.useState('');
    const hCsetControllerSurname = (event) => {
        setControllerSurname(event.target.value);
    };

    const [controllerPhone, setControllerPhone] = React.useState('');
    const hCsetControllerPhone = (event) => {
        setControllerPhone(event.target.value);
    };

    const [controllerMail, setControllerMail] = React.useState('');
    const hCsetControllerMail = (event) => {
        setControllerMail(event.target.value);
    };


    //Location Variable

    const [controllerServiceType, setControllerServiceType] = React.useState('');
    const hCsetControllerServiceType = (event) => {
        setControllerServiceType(event.target.value);
    };




    const [controllerNumberOfHours, setControllerNumberOfHours] = React.useState('');
    const hCsetControllerNumberOfHours = (event) => {
        setControllerNumberOfHours(event.target.value);
    };

    //

    const [controllerPickupOption, setControllerPickupOption] = React.useState('');
    const hCsetControllerPickupOption = (event) => {
        setControllerPickupOption(event.target.value);
    };

    const [controllerPickupAirport, setControllerPickupAirport] = React.useState('');
    const hCsetControllerPickupAirport = (event) => {
        setControllerPickupAirport(event.target.value);
    };

    const [controllerPickupAirportAirline, setControllerPickupAirportAirline] = React.useState('');
    const hCsetControllerPickupAirportAirline = (event) => {
        setControllerPickupAirportAirline(event.target.value);
    };

    const [controllerPickupAirportFlightNumber, setControllerPickupAirportFlightNumber] = React.useState('');
    const hCsetControllerPickupAirportFlightNumber = (event) => {
        setControllerPickupAirportFlightNumber(event.target.value);
    };

    const [controllerPickupAddress, setControllerPickupAddress] = React.useState('');
    const hCsetControllerPickupAddress = (event) => {
        setControllerPickupAddress(event.target.value);
    };

    const [controllerPickupAddressState, setControllerPickupAddressState] = React.useState('');
    const hCsetControllerPickupAddressState = (event) => {
        setControllerPickupAddressState(event.target.value);
    };

    //

    const [controllerDropoffOption, setControllerDropoffOption] = React.useState('');
    const hCsetControllerDropoffOption = (event) => {
        setControllerDropoffOption(event.target.value);
    };

    const [controllerDropoffAirport, setControllerDropoffAirport] = React.useState('');
    const hCsetControllerDropoffAirport = (event) => {
        setControllerDropoffAirport(event.target.value);
    };

    const [controllerDropoffAirportAirline, setControllerDropoffAirportAirline] = React.useState('');
    const hCsetControllerDropoffAirportAirline = (event) => {
        setControllerDropoffAirportAirline(event.target.value);
    };

    const [controllerDropoffAirportFlightNumber, setControllerDropoffAirportFlightNumber] = React.useState('');
    const hCsetControllerDropoffAirportFlightNumber = (event) => {
        setControllerDropoffAirportFlightNumber(event.target.value);
    };

    const [controllerDropoffAddress, setControllerDropoffAddress] = React.useState('');
    const hCsetControllerDropoffAddress = (event) => {
        setControllerDropoffAddress(event.target.value);
    };

    const [controllerDropoffAddressState, setControllerDropoffAddressState] = React.useState('');
    const hCsetControllerDropoffAddressState = (event) => {
        setControllerDropoffAddressState(event.target.value);
    };

    //Booking Info

    const [controllerCarId, setControllerCarId] = React.useState('');
    const hCsetControllerCarId = (event) => {
        setControllerCarId(event.target.value);
    };

    const [controllerPassengerCount, setControllerPassengerCount] = React.useState('');
    const hCsetControllerPassengerCount = (event) => {
        setControllerPassengerCount(event.target.value);
    };

    const [controllerLuggageCount, setControllerLuggageCount] = React.useState('');
    const hCsetControllerLuggageCount = (event) => {
        setControllerLuggageCount(event.target.value);
    };

    const [controllerNote, setControllerNote] = React.useState('');
    const hCsetControllerNote = (event) => {
        setControllerNote(event.target.value);
    };


    const [controllerChildseatCount, setControllerChildseatCount] = React.useState(false);
    const hCsetControllerChildseatCount = (event) => {
        setControllerChildseatCount(event.target.checked);
    };

    const [carsData, isloading] = useCollectionData(cars);





    const [activeStep, setActiveStep] = React.useState(0);

    const handleNext = () => {
        setActiveStep(activeStep + 1);
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    const handleSubmit = useCallback(async (e) => {

        const current = new Date();
        const datenow = `${current.getFullYear()}/${current.getMonth()}/${current.getDate()} ${current.getHours()}:${current.getMinutes()}`;

        e.preventDefault();
        const docRef = await addDoc(collection(db, 'booking'), {
            bookingId: "",
            userId: "",
            name: controllerName,
            surname: controllerSurname,
            phone: controllerPhone,
            mail: controllerMail,
            image: "",
            driverFullName: "",
            driverImage: "",
            driverPhone: "",
            otherName: controllerName,
            otherSurname: controllerSurname,
            otherPhone: controllerPhone,
            otherMail: controllerMail,
            serviceType: controllerServiceType,
            pickupOption: controllerPickupOption,
            pickupAddress: controllerPickupAddress,
            pickupAddressState: controllerPickupAddressState,
            pickupAddressLocation: "",
            pickupAirport: controllerPickupAirport,
            pickupAirportAirline: controllerPickupAirportAirline,
            pickupAirportFlightNumber: controllerPickupAirportFlightNumber,
            dropoffOption: controllerDropoffOption,
            dropoffAddress: controllerDropoffAddress,
            dropoffAddressState: controllerDropoffAddressState,
            dropoffAddressLocation: "",
            dropoffAirport: controllerDropoffAirport,
            dropoffAirportAirline: controllerDropoffAirportAirline,
            dropoffAirportFlightNumber: controllerDropoffAirportFlightNumber,
            carId: controllerCarId,
            passengerCount: controllerPassengerCount,
            luggageCount: controllerLuggageCount,
            childseatCount: controllerChildseatCount,
            note: controllerNote,
            numberOfHours: controllerNumberOfHours,
            createDate: Timestamp.fromDate(new Date(datenow)),
            pickupTime: Timestamp.fromDate(new Date(controllerPickupTime.toString())),
            updateDate: Timestamp.fromDate(new Date(datenow)),
            status: "1",
            state: true,
        });

        console.log("DocId: " + docRef.id);

        await updateDoc(doc(db, "booking", docRef.id), {
            bookingId: docRef.id
        });

        handleNext();
    });

    return (
        <ThemeProvider theme={theme}>

            <Helmet>
                <meta charSet="utf-8" />
                <title>ASAIRPORT LIMO AND CAR</title>
            </Helmet>

            <CssBaseline />
            <AppBar
                position="absolute"
                color="default"
                elevation={0}
                sx={{
                    position: 'relative',
                    borderBottom: (t) => `1px solid ${t.palette.divider}`,
                }}
            >
                <center>
                    <img src="https://www.asairportlimo.com/wp-content/uploads/2019/04/logo-as-airport-limousine.png" />
                </center>
            </AppBar>
            <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
                <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                    <Typography component="h1" variant="h4" align="center">
                        Booking
                    </Typography>
                    <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    {activeStep === steps.length ? (
                        <React.Fragment>
                            <Typography variant="h5" gutterBottom>
                                Thank you for your booking.
                            </Typography>
                            <Typography variant="subtitle1">
                                We have taken your booking confirmation, and will send you info when your booking has confirmed.                            </Typography>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>

                            {activeStep === 0 ? (
                                <React.Fragment>
                                    <Typography variant="h6" gutterBottom>
                                        Personal Information
                                    </Typography>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                required
                                                id="firstName"
                                                name="firstName"
                                                label="First name"
                                                fullWidth
                                                value={controllerName}
                                                onChange={hCsetControllerName}
                                                autoComplete="given-name" />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                required
                                                id="lastName"
                                                name="lastName"
                                                label="Last name"
                                                fullWidth
                                                value={controllerSurname}
                                                onChange={hCsetControllerSurname}
                                                autoComplete="family-name" />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                required
                                                id="phone"
                                                name="phone"
                                                label="Phone Number"
                                                fullWidth
                                                value={controllerPhone}
                                                onChange={hCsetControllerPhone}
                                                autoComplete="shipping address-line1" />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                required
                                                id="mail"
                                                name="mail"
                                                label="E-Mail Address"
                                                fullWidth
                                                value={controllerMail}
                                                onChange={hCsetControllerMail}
                                                autoComplete="shipping address-line2" />
                                        </Grid>
                                    </Grid>
                                </React.Fragment>
                            ) : (<React.Fragment></React.Fragment>)}
                            {activeStep === 1 ? (
                                <React.Fragment>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <Typography variant="h6" gutterBottom>
                                            Location
                                        </Typography>
                                        <Grid container spacing={3}>


                                            <Grid item xs={12} md={12}>
                                                <Select
                                                    required
                                                    value={controllerServiceType}
                                                    onChange={hCsetControllerServiceType}
                                                    displayEmpty
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                    fullWidth >
                                                    <MenuItem value="">
                                                        <em>Select Service Type</em>
                                                    </MenuItem>
                                                    <MenuItem value={"Point to Point"}>Point to Point</MenuItem>
                                                    <MenuItem value={"Hourly Service"}>Hourly Service</MenuItem>
                                                </Select>
                                            </Grid>

                                            {controllerServiceType === "Point to Point" || controllerServiceType === "Hourly Service" ? (

                                                <React.Fragment>

                                                    <Grid item xs={12} sm={12} md={12}>
                                                        <MobileDateTimePicker
                                                            required
                                                            label="PickUp Time"
                                                            value={controllerPickupTime}
                                                            onChange={hCsetControllerPickupTime}
                                                            fullWidth />
                                                    </Grid>

                                                    {controllerServiceType === "Hourly Service" ? (
                                                        <Grid item xs={12} sm={12}>
                                                            <TextField
                                                                required
                                                                id="lastName"
                                                                name="lastName"
                                                                label="Number Of Hours"
                                                                fullWidth
                                                                value={controllerNumberOfHours}
                                                                onChange={hCsetControllerNumberOfHours}
                                                                autoComplete="family-name" />
                                                        </Grid>
                                                    ) : (<React.Fragment></React.Fragment>)}



                                                    <Grid item xs={12} md={12}>
                                                        <Select
                                                            required
                                                            value={controllerPickupOption}
                                                            onChange={hCsetControllerPickupOption}
                                                            displayEmpty
                                                            inputProps={{ 'aria-label': 'Without label' }}
                                                            fullWidth >
                                                            <MenuItem value="">
                                                                <em>PickUp Location</em>
                                                            </MenuItem>
                                                            <MenuItem value={"Airport"}>Airport</MenuItem>
                                                            <MenuItem value={"Address"}>Address</MenuItem>
                                                        </Select>
                                                    </Grid>
                                                    {controllerPickupOption === "Airport" ? (
                                                        <React.Fragment>
                                                            <Grid item xs={12} md={12}>
                                                                <Select
                                                                    required
                                                                    value={controllerPickupAirport}
                                                                    onChange={hCsetControllerPickupAirport}
                                                                    displayEmpty
                                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                                    fullWidth >
                                                                    <MenuItem value="">
                                                                        <em>Chose an Airport</em>
                                                                    </MenuItem>
                                                                    <MenuItem value={"LGA - LaGuardia Airport"}>LGA - LaGuardia Airport</MenuItem>
                                                                    <MenuItem value={"EWR - Newark Liberty International Airport"}>EWR - Newark Liberty International Airport</MenuItem>
                                                                    <MenuItem value={"JFK - John F. Kennedy International Airport"}>JFK - John F. Kennedy International Airport</MenuItem>
                                                                </Select>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12}>
                                                                <TextField
                                                                    required
                                                                    id="lastName"
                                                                    name="lastName"
                                                                    label="Airline"
                                                                    fullWidth
                                                                    value={controllerPickupAirportAirline}
                                                                    onChange={hCsetControllerPickupAirportAirline}
                                                                    autoComplete="family-name" />
                                                            </Grid>
                                                            <Grid item xs={12} sm={12}>
                                                                <TextField
                                                                    required
                                                                    id="lastName"
                                                                    name="lastName"
                                                                    label="Flight Number"
                                                                    fullWidth
                                                                    value={controllerPickupAirportFlightNumber}
                                                                    onChange={hCsetControllerPickupAirportFlightNumber}
                                                                    autoComplete="family-name" />
                                                            </Grid>
                                                        </React.Fragment>
                                                    ) : (<React.Fragment></React.Fragment>)}
                                                    {controllerPickupOption === "Address" ? (
                                                        <React.Fragment>
                                                            <Grid item xs={12} md={12}>
                                                                <Select
                                                                    required
                                                                    value={controllerPickupAddressState}
                                                                    onChange={hCsetControllerPickupAddressState}
                                                                    displayEmpty
                                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                                    fullWidth >
                                                                    <MenuItem value="">
                                                                        <em>Chose an State</em>
                                                                    </MenuItem>
                                                                    <MenuItem value={"NY"}>NY</MenuItem>
                                                                    <MenuItem value={"NJ"}>NJ</MenuItem>
                                                                </Select>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12}>
                                                                <TextField
                                                                    required
                                                                    id="lastName"
                                                                    name="lastName"
                                                                    label="Address"
                                                                    fullWidth
                                                                    value={controllerPickupAddress}
                                                                    onChange={hCsetControllerPickupAddress}
                                                                    autoComplete="family-name" />
                                                            </Grid>
                                                        </React.Fragment>
                                                    ) : (<React.Fragment></React.Fragment>)}



                                                    {controllerServiceType === "Hourly Service" ? (
                                                        <Grid item xs={12} sm={12}>
                                                            <FormControlLabel
                                                                control={<Checkbox />}
                                                                label="Return at different location?" />
                                                        </Grid>
                                                    ) : (<React.Fragment></React.Fragment>)}



                                                    <Grid item xs={12} md={12}>
                                                        <Select
                                                            required
                                                            value={controllerDropoffOption}
                                                            onChange={hCsetControllerDropoffOption}
                                                            displayEmpty
                                                            inputProps={{ 'aria-label': 'Without label' }}
                                                            fullWidth >
                                                            <MenuItem value="">
                                                                <em>DropOff Location</em>
                                                            </MenuItem>
                                                            <MenuItem value={"Airport"}>Airport</MenuItem>
                                                            <MenuItem value={"Address"}>Address</MenuItem>
                                                        </Select>
                                                    </Grid>
                                                    {controllerDropoffOption === "Airport" ? (
                                                        <React.Fragment>
                                                            <Grid item xs={12} md={12}>
                                                                <Select
                                                                    required
                                                                    value={controllerDropoffAirport}
                                                                    onChange={hCsetControllerDropoffAirport}
                                                                    displayEmpty
                                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                                    fullWidth >
                                                                    <MenuItem value="">
                                                                        <em>Chose an Airport</em>
                                                                    </MenuItem>
                                                                    <MenuItem value={"LGA - LaGuardia Airport"}>LGA - LaGuardia Airport</MenuItem>
                                                                    <MenuItem value={"EWR - Newark Liberty International Airport"}>EWR - Newark Liberty International Airport</MenuItem>
                                                                    <MenuItem value={"JFK - John F. Kennedy International Airport"}>JFK - John F. Kennedy International Airport</MenuItem>
                                                                </Select>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12}>
                                                                <TextField
                                                                    required
                                                                    id="lastName"
                                                                    name="lastName"
                                                                    label="Airline"
                                                                    fullWidth
                                                                    value={controllerDropoffAirportAirline}
                                                                    onChange={hCsetControllerDropoffAirportAirline}
                                                                    autoComplete="family-name" />
                                                            </Grid>
                                                            <Grid item xs={12} sm={12}>
                                                                <TextField
                                                                    required
                                                                    id="lastName"
                                                                    name="lastName"
                                                                    label="Flight Number"
                                                                    fullWidth
                                                                    alue={controllerDropoffAirportFlightNumber}
                                                                    onChange={hCsetControllerDropoffAirportFlightNumber}
                                                                    autoComplete="family-name" />
                                                            </Grid>
                                                        </React.Fragment>
                                                    ) : (<React.Fragment></React.Fragment>)}
                                                    {controllerDropoffOption === "Address" ? (
                                                        <React.Fragment>
                                                            <Grid item xs={12} md={12}>
                                                                <Select
                                                                    required
                                                                    value={controllerDropoffAddressState}
                                                                    onChange={hCsetControllerDropoffAddressState}
                                                                    displayEmpty
                                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                                    fullWidth >
                                                                    <MenuItem value="">
                                                                        <em>Chose an State</em>
                                                                    </MenuItem>
                                                                    <MenuItem value={"NY"}>NY</MenuItem>
                                                                    <MenuItem value={"NJ"}>NJ</MenuItem>
                                                                </Select>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12}>
                                                                <TextField
                                                                    required
                                                                    id="lastName"
                                                                    name="lastName"
                                                                    label="Address"
                                                                    value={controllerDropoffAddress}
                                                                    onChange={hCsetControllerDropoffAddress}
                                                                    fullWidth
                                                                    autoComplete="family-name" />
                                                            </Grid>
                                                        </React.Fragment>
                                                    ) : (<React.Fragment></React.Fragment>)}
                                                </React.Fragment>
                                            ) : (<React.Fragment></React.Fragment>)}

                                        </Grid>
                                    </LocalizationProvider>
                                </React.Fragment>
                            ) : (<React.Fragment></React.Fragment>)}
                            {activeStep === 2 ? (
                                <React.Fragment>
                                    <Typography variant="h6" gutterBottom>
                                        Booking Info
                                    </Typography>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} md={12}>
                                            <Select
                                                required
                                                value={controllerCarId}
                                                onChange={hCsetControllerCarId}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                                fullWidth >
                                                <MenuItem value="">
                                                    <em>Select Car</em>
                                                </MenuItem>
                                                {carsData.map((post) => (
                                                    <MenuItem value={`${post.name}`}>
                                                        <div style={{
                                                            height: 70,
                                                            verticalalign: 'middle',
                                                            float: 'left',
                                                        }}>
                                                            <img style={{
                                                                float: 'left',
                                                            }} src={post.image} width="120" />
                                                            <div style={{
                                                                float: 'left',
                                                            }}>{`${post.name}`}</div>
                                                        </div>
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <TextField
                                                required
                                                id="lastName"
                                                name="lastName"
                                                label="Number of Passenger"
                                                fullWidth
                                                value={controllerPassengerCount}
                                                onChange={hCsetControllerPassengerCount}
                                                autoComplete="family-name" />
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <TextField
                                                required
                                                id="lastName"
                                                name="lastName"
                                                label="Loggage Count"
                                                fullWidth
                                                value={controllerLuggageCount}
                                                onChange={hCsetControllerLuggageCount}
                                                autoComplete="family-name" />
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <FormControlLabel control={<Checkbox value={controllerChildseatCount}
                                                onChange={hCsetControllerChildseatCount} />} label="Do you require a Car Seat?" />
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <TextField
                                                required
                                                id="lastName"
                                                name="lastName"
                                                label="Note"
                                                maxRows={5}
                                                minRows={5}
                                                multiline
                                                fullWidth
                                                value={controllerNote}
                                                onChange={hCsetControllerNote}
                                                autoComplete="family-name" />

                                        </Grid>
                                    </Grid>
                                </React.Fragment>
                            ) : (<React.Fragment></React.Fragment>)}
                            {activeStep === 3 ? (
                                <React.Fragment>
                                    <Typography variant="h6" gutterBottom>
                                        Booking Check
                                    </Typography>
                                    <List disablePadding>

                                        <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                                            <br></br>
                                        </Typography>

                                        <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                                            Personal Information
                                        </Typography>


                                        <ListItem sx={{ py: 1, px: 0 }}>
                                            <ListItemText primary="Name" />
                                            <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                                                {controllerName}
                                            </Typography>
                                        </ListItem>

                                        <ListItem sx={{ py: 1, px: 0 }}>
                                            <ListItemText primary="Surname" />
                                            <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                                                {controllerSurname}
                                            </Typography>
                                        </ListItem>

                                        <ListItem sx={{ py: 1, px: 0 }}>
                                            <ListItemText primary="Phone" />
                                            <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                                                {controllerPhone}
                                            </Typography>
                                        </ListItem>

                                        <ListItem sx={{ py: 1, px: 0 }}>
                                            <ListItemText primary="Mail" />
                                            <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                                                {controllerMail}
                                            </Typography>
                                        </ListItem>




                                        <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                                            <br></br>
                                        </Typography>

                                        <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                                            Location
                                        </Typography>

                                        <ListItem sx={{ py: 1, px: 0 }}>
                                            <ListItemText primary="Service Type" />
                                            <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                                                {controllerServiceType}
                                            </Typography>
                                        </ListItem>

                                        <ListItem sx={{ py: 1, px: 0 }}>
                                            <ListItemText primary="Time" />
                                            <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                                                {controllerPickupTime.toString()}
                                            </Typography>
                                        </ListItem>

                                        <ListItem sx={{ py: 1, px: 0 }}>
                                            <ListItemText primary="Number Of Hours" />
                                            <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                                                {controllerNumberOfHours}
                                            </Typography>
                                        </ListItem>

                                        <ListItem sx={{ py: 1, px: 0 }}>
                                            <ListItemText primary="PickUp Location" />
                                            <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                                                {controllerPickupOption}
                                            </Typography>
                                        </ListItem>

                                        <ListItem sx={{ py: 1, px: 0 }}>
                                            <ListItemText primary="PickUp Airport" />
                                            <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                                                {controllerPickupAirport}
                                            </Typography>
                                        </ListItem>

                                        <ListItem sx={{ py: 1, px: 0 }}>
                                            <ListItemText primary="PickUp Airline" />
                                            <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                                                {controllerPickupAirportAirline}
                                            </Typography>
                                        </ListItem>

                                        <ListItem sx={{ py: 1, px: 0 }}>
                                            <ListItemText primary="PickUp Flight Number" />
                                            <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                                                {controllerPickupAirportFlightNumber}
                                            </Typography>
                                        </ListItem>

                                        <ListItem sx={{ py: 1, px: 0 }}>
                                            <ListItemText primary="PickUp State" />
                                            <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                                                {controllerPickupAddressState}
                                            </Typography>
                                        </ListItem>

                                        <ListItem sx={{ py: 1, px: 0 }}>
                                            <ListItemText primary="PickUp Address" />
                                            <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                                                {controllerPickupAddress}
                                            </Typography>
                                        </ListItem>



                                        <ListItem sx={{ py: 1, px: 0 }}>
                                            <ListItemText primary="DropOff Location" />
                                            <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                                                {controllerDropoffOption}
                                            </Typography>
                                        </ListItem>

                                        <ListItem sx={{ py: 1, px: 0 }}>
                                            <ListItemText primary="DropOff Airport" />
                                            <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                                                {controllerDropoffAirport}
                                            </Typography>
                                        </ListItem>

                                        <ListItem sx={{ py: 1, px: 0 }}>
                                            <ListItemText primary="DropOff Airline" />
                                            <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                                                {controllerDropoffAirportAirline}
                                            </Typography>
                                        </ListItem>

                                        <ListItem sx={{ py: 1, px: 0 }}>
                                            <ListItemText primary="DropOff Flight Number" />
                                            <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                                                {controllerDropoffAirportFlightNumber}
                                            </Typography>
                                        </ListItem>

                                        <ListItem sx={{ py: 1, px: 0 }}>
                                            <ListItemText primary="DropOff State" />
                                            <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                                                {controllerDropoffAddressState}
                                            </Typography>
                                        </ListItem>

                                        <ListItem sx={{ py: 1, px: 0 }}>
                                            <ListItemText primary="DropOff Address" />
                                            <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                                                {controllerDropoffAddress}
                                            </Typography>
                                        </ListItem>






                                        <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                                            <br></br>
                                        </Typography>

                                        <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                                            Booking Information
                                        </Typography>


                                        <ListItem sx={{ py: 1, px: 0 }}>
                                            <ListItemText primary="Car" />
                                            <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                                                {controllerCarId}
                                            </Typography>
                                        </ListItem>

                                        <ListItem sx={{ py: 1, px: 0 }}>
                                            <ListItemText primary="Number of Passenger" />
                                            <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                                                {controllerPassengerCount}
                                            </Typography>
                                        </ListItem>

                                        <ListItem sx={{ py: 1, px: 0 }}>
                                            <ListItemText primary="Loggage Count" />
                                            <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                                                {controllerLuggageCount}
                                            </Typography>
                                        </ListItem>

                                        <ListItem sx={{ py: 1, px: 0 }}>
                                            <ListItemText primary="Car Seat" />
                                            <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                                                {controllerChildseatCount.toString()}
                                            </Typography>
                                        </ListItem>

                                        <ListItem sx={{ py: 1, px: 0 }}>
                                            <ListItemText primary="Note" />
                                            <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                                                {controllerNote}
                                            </Typography>
                                        </ListItem>


                                    </List>

                                </React.Fragment>
                            ) : (<React.Fragment></React.Fragment>)}

                            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                {activeStep !== 0 && (
                                    <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                                        Back
                                    </Button>
                                )}

                                {activeStep === steps.length - 1 ? (
                                    < Button
                                        variant="contained"
                                        onClick={handleSubmit}
                                        sx={{ mt: 3, ml: 1 }}
                                    >Book Now</Button>
                                ) : (
                                    <Button
                                        variant="contained"
                                        onClick={handleNext}
                                        sx={{ mt: 3, ml: 1 }}
                                    >Next</Button>
                                )}
                            </Box>
                        </React.Fragment>
                    )}
                </Paper>
            </Container>
        </ThemeProvider >
    );
}

export default Booking;