import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyAPylhYLrzaRHBC-zDtzmvkNRtxzeS6VAs",
    authDomain: "airportlimo-app.firebaseapp.com",
    databaseURL: "https://airportlimo-app-default-rtdb.firebaseio.com",
    projectId: "airportlimo-app",
    storageBucket: "airportlimo-app.appspot.com",
    messagingSenderId: "602281037474",
    appId: "1:602281037474:web:1f39089b20e343add451fb",
    measurementId: "G-E8GH2CB916"
};

const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const db = getFirestore(app);

